

@include large-mobile {
  .default__bg-layer {
    .header {
      height: unset;
      padding-top: 20px;
      margin: 0 40px 67px;
      width: calc(100% - 80px);
    }

  }
}

@include tablet {
  .default__bg-layer {
    .footer {
      padding-bottom: 10px;
    }
  }
}

@include desktop {
  .default__bg-layer {
    margin: 0 auto;

    .header {
      padding-top: 55px;
      margin: 0 150px 82px;
      width: calc(100% - 300px);
    }

    .footer {
    }
  }
}



.testimonials {
  align-content: center;
  margin: 40px 0 100px 0;

  .ant-card-head {
    color: $white;

    border-radius: 10px 10px 0 0;
  }

  .testimonial-card {
    margin: 15px 20px;
    color: $white;

    &.ant-card {
      background: $darkBox;
      color: $white;

      a {
        color: $primaryColor;
      }

      &.ant-card-bordered {
        border-color: $darkInput;
      }
    }
  }
}
